<template>
  <vx-card class="tabla-admin-franquicias">
    <!-- TABLE ACTION ROW -->
    <div class="flex justify-between items-center">
      <vs-input
        class="mb-4 w-full md:mb-0 mr-4"
        v-model="searchQuery"
        @input="updateSearchQuery"
        placeholder="Buscar..."
      />
    </div>
    <ag-grid-vue
      ref="agGridTable"
      :gridOptions="gridOptions"
      class="ag-theme-material w-100 my-4 ag-grid-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="franchises"
      rowSelection="multiple"
      colResizeDefault="shift"
      :animateRows="true"
      :floatingFilter="false"
      :pagination="true"
      :paginationPageSize="paginationPageSize"
      :suppressPaginationPanel="true"
      :enableRtl="$vs.rtl"
    >
    </ag-grid-vue>
    <vs-pagination
      :total="totalPages"
      :max="maxPageNumbers"
      v-model="currentPage"
    />
  </vx-card>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import CellRendererActions from './cell-renderer/CellRendererActions';
import { ADMIN_FRANCHISES } from '@/graphql/queries';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { useQuery, useResult } from '@vue/apollo-composable';
export default defineComponent({
  components: {
    AgGridVue,

    CellRendererActions,
  },
  data() {
    return {
      searchQuery: '',
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: 'Ref',
          field: 'id',
          width: 300,
          filter: true,
        },
        {
          headerName: 'Franquicia',
          field: 'franchise.name',
          width: 300,
          filter: true,
        },
        {
          headerName: 'Nombre',
          field: 'first_name',
          width: 300,
          filter: true,
        },
        {
          headerName: 'Apellido',
          field: 'last_name',
          width: 200,
          filter: true,
        },
        {
          headerName: 'Email',
          field: 'email',
          width: 300,
          filter: true,
        },
        {
          headerName: 'Telefono',
          field: 'phone',
          width: 300,
          filter: true,
        },
        {
          headerName: 'Acciones',
          field: 'acciones',
          width: 200,
          filter: true,
          cellRendererFramework: 'CellRendererActions',
        },
      ],
    };
  },

  setup() {
    const currentPage = ref(1);
    const first = ref(15);
    const { result: franchisesResult } = useQuery(ADMIN_FRANCHISES, {
      page: currentPage,
      first: first,
    });
    const franchises = useResult(
      franchisesResult,
      [],
      (data) => data.adminFranchises.data
    );
    const paginatorInfo = useResult(franchisesResult, [], (data) => ({
      total: data.adminFranchises.paginatorInfo.total,
      perPage: data.adminFranchises.paginatorInfo.perPage,
    }));
    const totalPages = computed(() =>
      Math.ceil(paginatorInfo.value.total / paginatorInfo.value.perPage)
    );
    return {
      totalPages,
      paginatorInfo,
      franchises,
      currentPage,
    };
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 50;
    },
  },
  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
  },
});
</script>
